<script lang="ts">
  import {
    Menu,
    MenuButton,
    MenuItems,
    Transition,
  } from "@rgossiaux/svelte-headlessui";

  export let disabled: boolean = false;
  export let align: "left" | "right" | "full" = "left";
</script>

<Menu let:open>
  <MenuButton {disabled} as="div">
    <slot name="button" />
  </MenuButton>

  <Transition show={open} class="relative z-30">
    <MenuItems class="outline-none">
      <div
        class="menu-items"
        class:left-0={align === "left" || align === "full"}
        class:right-0={align === "right" || align === "full"}
      >
        <slot />
      </div>
    </MenuItems>
  </Transition>
</Menu>

<style lang="postcss">
  .menu-items {
    @apply absolute mt-2 rounded-lg bg-white/[1.5%] border border-white/10 backdrop-blur-lg;
  }

  .menu-items :global(hr) {
    @apply border-white/10 my-1.5;
  }
</style>
